import React, { Component } from 'react';

import { Segment, Form, Input, Button, Icon } from 'semantic-ui-react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PubSub from 'pubsub-js';

var request = require("request");


class AlterarSenha extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      senha1: '',
      senha2: ''
    };
  }
  
  handleChange(event) {
    let opc = event.target.id, value = event.target.value;
    this.setState({
      [opc]: value
    })
  }
  
  voltar(){
    PubSub.publish('componente', { componente:'' });
  }
  
  salvar() {
    const { senha1, senha2 } = this.state;
    if (senha1 !== senha2 || senha1.length < 8 || senha2.length < 8) {
      NotificationManager.error('Senhas precisam ser iguais e ter no mínimo 8 caracteres', '');
    } else {
      var options = {
        method: 'PUT',
        url: 'https://inteligenciaindustrial.com/budgetind/usuarios',
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },

        body: { key:localStorage.getItem("keyUsuario"), senha: senha1, alterarsenha: true },
        json: true,
      };
      
      request(options, function(error, response, body) {
        if (body.mensagem === undefined) {
          this.setState({senha1: '',senha2: ''});
          NotificationManager.success('Senha alterada com sucesso', '');
        } else {
          NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
          setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
        }
      }.bind(this));
    }
  }


  
  render() {
    return(
      <div>
        <Segment style={{width: 'auto', maxWidth: '650px', margin: 'auto'}}>
          <NotificationContainer/>
          <Form>
            <div style = {{ paddingTop: "0px", paddingBottom: "15px", paddingRight: "0px", textAlign: "right" }}>
              <Icon name='reply' link  onClick={this.voltar}/>
            </div>
            <Form.Group widths='equal'>
              <Form.Input fluid label='Digite uma nova Senha' type='password' id = "senha1"
                          value = {this.state.senha1} onChange={this.handleChange.bind(this)} />
              <Form.Input fluid label='Digite a senha novamente para confirmá-la' type='password'
                          id = "senha2" value = {this.state.senha2} onChange={this.handleChange.bind(this)} />
            </Form.Group>
            <Button icon labelPosition='right' color='blue' fluid size='large' onClick={this.salvar.bind(this)}>
              Salvar
              <Icon name='sign-in' />
            </Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

export default AlterarSenha;