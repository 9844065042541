import React, { Component } from 'react';

import "react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css";
//import * as moment from 'moment';
//import 'moment/locale/pt-br';

import './App.css';

import PubSub from 'pubsub-js';
import { Grid, Sidebar, Menu, Icon, Segment } from 'semantic-ui-react';


import Login from './autenticacao/login';
import Principal from './App_.js';
import AlterarSenha from './componentes/alterasenha/index.js';
import PlanoProducao from './componentes/planoproducao/index.js';
import Headcount from './componentes/headcount/index.js';
import logo_ from './img/budgetind_logo.png';

const request = require("request");

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      component:'login',
      usuario:'', 
      permissao:'', 
      exibeMenuSuperior: false, 
      componenteExibir: '',
      componentePlano_producao: false,
      componenteHeadcount: false
      
    }
    this.exibeMenuSuperior = this.exibeMenuSuperior.bind(this);
    this.sair = this.sair.bind(this);
    this.exibeComponenteAlterarSenha = this.exibeComponenteAlterarSenha.bind(this);
    this.exibeComponentePlanoProducao = this.exibeComponentePlanoProducao.bind(this);
    this.exibeComponenteHeadcount = this.exibeComponenteHeadcount.bind(this);
    this.validaFuncionalidadesPerfil = this.validaFuncionalidadesPerfil.bind(this);
  }
  
  componentDidMount(){    
    PubSub.subscribe('componente', function (topic, data) {
      this.setState({
        componenteExibir: data.componente
      })
    }.bind(this));
    
    PubSub.subscribe('autenticacao', function (topic, data) {                
      if(data.validado === true){
        this.validaFuncionalidadesPerfil(data.usuario);
        this.setState({component:'principal', usuario:data.usuario, permissao:data.permissao})
      }else if(data.validado === false){
        this.setState({component:'login'})
      }
    }.bind(this));        
  }
  
  validaFuncionalidadesPerfil(usuario){
    let componentePlano_producao = false, componenteHeadcount = false;
    const options_funcionalidades_perfil = { 
      method: 'GET', 
      url: 'https://inteligenciaindustrial.com/budgetind/opcoesfiltros/funcionalidades/'+usuario.perfil,
      headers: {
        'content-type': 'application/json',
        'x-access-token': localStorage.getItem("token")
      },
    };
    
    request(options_funcionalidades_perfil, function (error, response, body) {
      let funcionalidades = [];
      if (JSON.parse(body)) {
        if (JSON.parse(body).length > 0) {
          funcionalidades = JSON.parse(body);
          
          funcionalidades.forEach(function(f){
            if (f.codigo_funcionalidade === "plano_producao") {
              componentePlano_producao = true
            }
            if (f.codigo_funcionalidade === "headcount") {
              componenteHeadcount = true
            }
          });
          this.setState({
            componentePlano_producao: componentePlano_producao, componenteHeadcount: componenteHeadcount
          });
        }
      }
    }.bind(this));
  }
  
  exibeMenuSuperior() {
    const { exibeMenuSuperior } = this.state;
    this.setState({
      exibeMenuSuperior: exibeMenuSuperior === true ? false : true
    });
  }
  
  sair() {
    this.setState({
      componenteExibir: '', component: 'login'
    })
  }
  
  exibeComponenteAlterarSenha() {
    this.setState({
      component : 'principal',  componenteExibir: 'alterarSenha'
    })
  }
  
  exibeComponentePlanoProducao() {
    this.setState({
      component : 'principal', componenteExibir: 'planoProducao'
    });
  }
  
  exibeComponenteHeadcount() {
    console.log("headcount")
    this.setState({
      component : 'principal', componenteExibir: 'headcount'
    });
  }

  
  render() {
    const { exibeMenuSuperior, componenteExibir } = this.state;
    let objetoComponente;
    
    
    switch(componenteExibir) {
      case 'alterarSenha':
        objetoComponente = <AlterarSenha />
        break;
      case 'planoProducao':
        objetoComponente = <PlanoProducao usuario={this.state.usuario}/>
        break;
      case 'headcount':
        objetoComponente = <Headcount usuario={this.state.usuario} />
        break;
      default:
        objetoComponente = <Principal usuario={this.state.usuario} permissao={this.state.permissao} />
    }

    
    
    if (this.state.component === 'login') {
      return (<Login/>);
    } else if (this.state.component === 'principal') {
        return(
          <div>
          <Segment  basic attached='top' style={{padding: '0px'}}>
            <Grid>
              <Grid.Column width={2}>
                <Icon name = 'sidebar' link style={{ marginTop:'20px', marginLeft:'10px' }} disabled = {exibeMenuSuperior} 
                      onClick = { this.exibeMenuSuperior }/>
              </Grid.Column >
              <Grid.Column width={12} style={{textAlign: 'center'}}>
                <img src={logo_} style={{width:'200px'}}/>
              </Grid.Column>
              <Grid.Column width={2}></Grid.Column>
            </Grid>
            </Segment>
          <Sidebar.Pushable style = {{ padding: '0px'}}>
              <Sidebar as = { Menu } animation = 'overlay' inverted onHide = { this.exibeMenuSuperior } 
                       direction = 'top' visible = { exibeMenuSuperior } width = 'thin'>
                {
                  this.state.componentePlano_producao &&
                  <Menu.Item as = 'a' onClick = { this.exibeComponentePlanoProducao}>
                    <Icon name = 'industry' />
                    Plano de Produção
                  </Menu.Item>
                }
                {
                  this.state.componenteHeadcount &&
                  <Menu.Item as = 'a' onClick = { this.exibeComponenteHeadcount}>
                    <Icon name = 'users' />
                    Headcount
                  </Menu.Item>
                }
                <Menu.Item as = 'a' onClick = { this.exibeComponenteAlterarSenha }>
                  <Icon name = 'edit outline' />
                    Alterar Senha
                </Menu.Item>
                <Menu.Item as = 'a' onClick = { this.sair }>
                  <Icon name = 'log out' />
                    Sair
                </Menu.Item>
              </Sidebar>
              <Sidebar.Pusher>
                <Segment basic>
                  <div>{objetoComponente}</div>
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        )
    }    
  }
}

export default App;
