import React, { Component } from 'react';

import { Segment, Form, Input, Button, Icon, Header, Grid, Dimmer, Loader } from 'semantic-ui-react';
import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PubSub from 'pubsub-js';

var request = require("request");

const formatDecimal = require('format-decimal');
var opts  = {decimal   : ',', precision : 0,thousands : '.'}


class PlanoProducao extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loaderDados: false,
      data_plano_producao: [],
      data_plano_producao_colunas: [
          { title: "FÁBRICA", field: "fabrica", width:130, align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "PRODUTO", field: "produto", width:265, align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "JAN", field: "JAN", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "FEV", field: "FEV", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "MAR", field: "MAR", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
               return value
              }
            }
          },
          { title: "ABR", field: "ABR", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "MAI", field: "MAI", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "JUN", field: "JUN", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
               return value
              }
            }
          },
          { title: "JUL", field: "JUL", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "AGO", field: "AGO", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "SET", field: "SET", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "OUT", field: "OUT", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "NOV", field: "NOV", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "DEZ", field: "DEZ", align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
              if (cell._cell.row.data.fabrica.match(/TOTAL/) !== null) {
                return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
              } else {
                return value
              }
            }
          },
          { title: "TOTAL GERAL", field: "TOTAL_GERAL", width:120, align: "center", headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              value = value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0;
               return "<span style='font-weight:bold;font-size: 11px; color: #2e75b6'>" + value + "</span>";
            }
          }
        ]
    };
  }
  
  componentDidMount() {
    console.log(this.props.usuario.filial);
    this.setState({
      loaderDados: true
    });
    let options_plano_producao = { 
      method: 'POST',
      url: 'https://inteligenciaindustrial.com/budgetind/planoproducaobudgetind/planoproducao',                    
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
      },
      body: { codigo_unidade_fabril: this.props.usuario.filial },
      json: true
    };
    
    request(options_plano_producao, function (error, response, body) {
      if(body.mensagem === undefined) {
          if(body.length > 0) {
            this.setState({ data_plano_producao:body, loaderDados: false });
         }
       } else {
         this.setState({ loaderDados: false })
         NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
         setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
       }
    }.bind(this));
  }
  
  voltar(){
    PubSub.publish('componente', { componente:'' });
  }
  
  render() {
    return(
      <div>
        <NotificationContainer/>
          <Grid columns='equal'>
            <Grid.Column ></Grid.Column>
            <Grid.Column width={8} style = {{ textAlign: "center" }}>
              <Header size='large' style = {{ paddingBottom: "15px" }}>
                Plano de Produção 2020
              </Header>
            </Grid.Column>
            <Grid.Column style = {{ textAlign: "right" }}>
              <Icon name='reply' link onClick={this.voltar}/> 
            </Grid.Column>
          </Grid>
        <Segment style={{width: 'auto', margin: 'auto', padding: '0px'}}>
          { 
            this.state.loaderDados === true && <Dimmer active inverted>
              <Loader inverted content='Carregando dados' />
            </Dimmer>
          }
          <ReactTabulator data={this.state.data_plano_producao} style={{ height: '-webkit-fill-available',margin:'0px', fontSize:'10px','border':'0px'}}
                          options={this.state.options} className={'very compact celled'}
                          columns={this.state.data_plano_producao_colunas}
                          tooltips={true}
                          layout={"fitColumns"}/> 
        </Segment>
      </div>
    )
  }
}

export default PlanoProducao;