import React, { Component } from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PubSub from 'pubsub-js';


import { Table, Header, Container,TextArea, Segment, Icon, Modal, Button, Input, Menu, Dimmer, Loader, Form, Dropdown, Grid, Divider, Card, Label } from 'semantic-ui-react';
import logo from './logo.svg';
import './App.css';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'amcharts3/amcharts/amcharts';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/themes/light';
import AmCharts from "@amcharts/amcharts3-react";

import logo_ from './img/budgetind_logo.png'

var request = require("request");

const formatDecimal = require('format-decimal');
var opts  = {decimal   : ',', precision : 2,thousands : '.'}
var opts2 = {decimal   : ',', precision : 0,thousands : '.'}

const justificativaIcon = function(cell, formatterParams) { //plain text value
  if (cell._cell.row.data.JUSTIFICATIVA) {
    return '<i aria-hidden="true" class="info icon"></i>';
  }
};

class App extends Component {
   constructor(props){
    super(props);
      this.state = {
        loaderGrafico: false,
        loaderJustificativa: false,
        loaderPesquisar: false,
        modalHeader:"",
        open:false,
        openFiltros: true,
        dataProvider: [],
        filial:[],
        orcamento:[],
        usuarios:[],
        dataJustificativas: [],
        descricaoJustificativa: 'TESTE',
        openModalEditaJustificativa: false,
        openModalTabelaJustificativas: false,
        mensagenStatus: '',
        //ano: [ { key: '2019', value: '2019', text: '2019' },{ key: '2018', value: '2018', text: '2018' }],
        ano: [],
        mes: [ { key: '(.*)', value: '(.*)', text: 'TUDO' },
               { key: 'JAN', value: 'JAN', text: 'JAN' }, 
               { key: 'FEV', value: 'FEV', text: 'FEV' }, 
               { key: 'MAR', value: 'MAR', text: 'MAR' }, 
               { key: 'ABR', value: 'ABR', text: 'ABR' }, 
               { key: 'MAI', value: 'MAI', text: 'MAI' }, 
               { key: 'JUN', value: 'JUN', text: 'JUN' }, 
               { key: 'JUL', value: 'JUL', text: 'JUL' }, 
               { key: 'AGO', value: 'AGO', text: 'AGO' }, 
               { key: 'SET', value: 'SET', text: 'SET' }, 
               { key: 'OUT', value: 'OUT', text: 'OUT' }, 
               { key: 'NOV', value: 'NOV', text: 'NOV' }, 
               { key: 'DEZ', value: 'DEZ', text: 'DEZ' }],
        geral:[{VALOR_ORCADO:0,VALOR_REALIZADO:0,VARIACAO_VALOR_MONETARIO:0,VARIACAO_PERCENTUAL:0}],
        desc_filial_selecionadoJustificativa: '',
        anos_selecionadoJustificativa: '',
        mes_abv_selecionadoJustificativa: '',
        desc_area_selecionadoJustificativa: '',
        area:[],
        areaJustificativas:[],
        mensal: [],
        agrupamento:[],
        editarJustificativa: false,
        centro_de_custo:[],
        conta_contabil:[],
        data_area:[],
        data_agrupamento:[],
        data_centro_de_custo:[],
        data_conta_contabil:[],
        data_area_colunas : [
            { title: "ÁREA", field: "DESC_AREA", align: "center" },
            { title: "ORÇADO", field: "VALOR_ORCADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
            { title: "REAL.", field: "VALOR_REALIZADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
            { title: "VAR. $", field: "VARIACAO_VALOR_MONETARIO", align: "center", formatter:function(cell, formatterParamns){
                var value = cell.getValue();
                if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                }else{
                  return 0
                }
              } 
            },
            { title: "VAR. %", field: "VARIACAO_PERCENTUAL", align: "center", formatter:function(cell, formatterParams){
                var value = cell.getValue();
                if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + value + "</span>";
                }else{
                  return 0
                }
              } 
            },
          { title: "", align: "center", cellClick: this.openModalTabelaJustificativas.bind(this), headerSort: false, responsive: 0, formatter: justificativaIcon, width: 40 }

        ],
        data_agrupamento_colunas: [
          { title: "AGRUPAMENTO", field: "AGRUPAMENTO", align: "center", headerFilter:"input", headerFilterPlaceholder:" " },
          { title: "ORÇADO", field: "VALOR_ORCADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "REAL", field: "VALOR_REALIZADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "VAR. $", field: "VARIACAO_VALOR_MONETARIO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                }else{
                  return 0
                }
            } 
          },
          { title: "VAR. %", field: "VARIACAO_PERCENTUAL", align: "center", formatter:function(cell, formatterParams){
              var value = cell.getValue();
              if (value > 0) {
                return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
              } else if(value < 0) {
                return "<span style='color:green; font-weight:bold;'>" + value + "</span>";
              }else{
                return 0
              }
            } 
          },
          { title: "", align: "center", cellClick: this.openModalTabelaJustificativas.bind(this), headerSort: false, responsive: 0, formatter: justificativaIcon, width: 40 }
        ],
        data_centro_de_custo_colunas: [
          { title: "CENTRO DE CUSTO", field: "DESC_CENTRO_DE_CUSTO", align: "center", headerFilter:"input", headerFilterPlaceholder:" " },
          { title: "ORÇADO", field: "VALOR_ORCADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "REAL", field: "VALOR_REALIZADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "VAR. $", field: "VARIACAO_VALOR_MONETARIO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                }else{
                  return 0
                }
            } },
          { title: "VAR. %", field: "VARIACAO_PERCENTUAL", align: "center", formatter:function(cell, formatterParams){
              var value = cell.getValue();
              if (value > 0) {
                return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
              } else if(value < 0) {
                return "<span style='color:green; font-weight:bold;'>" + value + "</span>";
              }else{
                return 0
              }
            }
          },
          { title: "", align: "center", cellClick: this.openModalTabelaJustificativas.bind(this), headerSort: false, responsive: 0, formatter: justificativaIcon, width: 40 }
        ],
        data_conta_contabil_colunas: [
          { title: "CONTA CONTÁBIL", field: "DESC_CONTA_CONTABIL", align: "center", headerFilter:"input", headerFilterPlaceholder:" " },
          { title: "ORÇADO", field: "VALOR_ORCADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "REAL", field: "VALOR_REALIZADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "VAR. $", field: "VARIACAO_VALOR_MONETARIO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                }else{
                  return 0
                }
            } },
          { title: "VAR. %", field: "VARIACAO_PERCENTUAL", align: "center", formatter:function(cell, formatterParams){
              var value = cell.getValue();
              if (value > 0) {
                return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
              } else if(value < 0) {
                return "<span style='color:green; font-weight:bold;'>" + value + "</span>";
              }else{
                return 0
              }
            } 
          },
          { title: "", align: "center", cellClick: this.openModalTabelaJustificativas.bind(this), headerSort: false, responsive: 0, formatter: justificativaIcon, width: 40 }
        ],
        data_justificativa: [],
      }
     this.openModalEditaJustificativa = this.openModalEditaJustificativa.bind(this);
     this.openModalTabelaJustificativas = this.openModalTabelaJustificativas.bind(this);
     this.handleChangDescricaoJustificativa = this.handleChangDescricaoJustificativa.bind(this);
     this.formataMes = this.formataMes.bind(this);
     this.usuarioJustificativa = this.usuarioJustificativa.bind(this);
  } 

  componentDidMount(){
    let perfilUsuario = this.props.usuario.perfil;
    let anoCorrente = new Date().getFullYear();
    
    anoCorrente = [`${anoCorrente}`];
    
   
    this.setState({
      loaderPesquisar: true
    });
    let Filial = [], Area = [], AreaJustificativas = [], Agrupamento = [], Centro_de_custo = [], Conta_contabil = [], FiltrosPerfil = [], Usuarios = [], Anos = [];
    let valoresFilial = "", valoresAreas = "", valoresAgrupamentos = "", valoresCentroCustos = "", valoresContaContabil = "";
    
    var options_agrupamento = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/filtro/agrupamento',
     headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
    };
    
    var options_anos = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/anos',
     headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
    };
    
    var options_centro_de_custo = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/filtro/centro_de_custo',
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
    var options_conta_contabil = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/filtro/conta_contabil',
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
    var options_filtros_perfil = { method: 'GET', 
        url: 'https://inteligenciaindustrial.com/budgetind/opcoesfiltros/filtros/'+perfilUsuario,
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
    };
    
    var options_usuarios = { method: 'GET', 
        url: 'https://inteligenciaindustrial.com/budgetind/usuarios',
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
    };
    
    
    request(options_filtros_perfil, function (error, response, body) {
      if(JSON.parse(body).mensagem === undefined) {
        FiltrosPerfil = JSON.parse(body);
        
        request(options_anos, function (error, response, body) {
          Anos = JSON.parse(body);
          
          request(options_agrupamento, function (error, response, body) {
            Agrupamento = JSON.parse(body);

            request(options_usuarios, function (error, response, body) {
              Usuarios = JSON.parse(body);

              request(options_centro_de_custo, function (error, response, body) {
                Centro_de_custo = JSON.parse(body);

                request(options_conta_contabil, function (error, response, body) {
                  Conta_contabil = JSON.parse(body);

                  FiltrosPerfil.forEach(function (value, index) {
                    if (value.coluna_valor_filtro === 'filial') {
                      Filial.push({key:value.valor_filtro,value:value.valor_filtro,text:value.valor_filtro});
                    }

                    if (value.coluna_valor_filtro === 'area') {
                      Area.push({key:value.valor_filtro,value:value.valor_filtro,text:value.descricao_valor_filtro});
                      if (value.valor_filtro !== '(.*)') {
                        AreaJustificativas.push({key:value.valor_filtro,value:value.valor_filtro,text:value.descricao_valor_filtro});
                      }
                    }
                  });
                  
                  if (Area[0].value === "TUDO") {
                    valoresAreas = ['(.*)'];
                  } else {
                    valoresAreas = [`${Area[0].value}`];
                  }
                  
                  if (Agrupamento[0].value === "TUDO") {
                    valoresAgrupamentos = ['(.*)'];
                  } else {
                    valoresAgrupamentos = [`${Agrupamento[0].value}`];
                  }
                  
                  if (Centro_de_custo[0].value === "TUDO") {
                    valoresCentroCustos = ['(.*)'];
                  } else {
                    valoresCentroCustos = [`${Centro_de_custo[0].value}`];
                  }
                  
                  if (Conta_contabil[0].value === "TUDO") {
                    valoresContaContabil = ['(.*)'];
                  } else {
                    valoresContaContabil = [`${Conta_contabil[0].value}`];
                  }
                  
                  if (Filial.length === 1) {
                    valoresFilial = [`${Filial[0].value}`];
                  } else {
                    if (this.props.usuario.filial === 'CORPORATIVO') {
                      valoresFilial = ["PTR", "FRT"];
                    } else if (this.props.usuario.filial === 'PETROPOLIS' || this.props.usuario.filial === 'petropolis' || this.props.usuario.filial === 'PETRÓPOLIS' || this.props.usuario.filial === 'petrópolis') {
                      valoresFilial = ["PTR"];
                    } else if (this.props.usuario.filial === 'FRUTAL' || this.props.usuario.filial === 'frutal') {
                      valoresFilial = ["FRT"];
                    }
                  }

                  this.setState({ ano: Anos, filial: Filial, area: Area, areaJustificativas: AreaJustificativas, agrupamento: Agrupamento, centro_de_custo: Centro_de_custo, 
                                  conta_contabil: Conta_contabil, usuarios: Usuarios, loaderPesquisar: false,
                                  desc_filial_selecionados: valoresFilial, anos_selecionados: anoCorrente, mes_abv_selecionados: ['(.*)'], 
                                  desc_area_selecionados: valoresAreas, agrupamento_selecionados: valoresAgrupamentos, 
                                  desc_centro_de_custo_selecionados: valoresCentroCustos, desc_conta_contabil_selecionados: valoresContaContabil});
                }.bind(this));
              }.bind(this));
            }.bind(this));
          }.bind(this));
        }.bind(this));
      } else {
        NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
        setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
      }
    }.bind(this));
    
    
    
    
    

  }
  
  pesquisar(){
    this.setState({
      loaderPesquisar: true, mensagenStatus: 'Preparando a consulta...'
    });
    
    
    if (this.state.desc_filial_selecionados && this.state.desc_filial_selecionados.length > 0 && 
        this.state.anos_selecionados && this.state.anos_selecionados.length > 0 && 
        this.state.mes_abv_selecionados && this.state.mes_abv_selecionados.length > 0 &&
        this.state.desc_area_selecionados && this.state.desc_area_selecionados.length > 0 && 
        this.state.agrupamento_selecionados && this.state.agrupamento_selecionados.length > 0 &&
        this.state.desc_centro_de_custo_selecionados && this.state.desc_centro_de_custo_selecionados.length > 0 &&
        this.state.desc_conta_contabil_selecionados && this.state.desc_conta_contabil_selecionados.length > 0) {
      var options_geral = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/geral',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        },                      
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
      };

      var options_area = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/area',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        },                      
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
      };
      
      var options_area_justificativas = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/area/justificativas',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        },                      
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
      };

      var options_agrupamento = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/agrupamento',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        }, 
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
      };
      
      var options_agrupamento_justificativas = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/agrupamento/justificativas',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        }, 
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
      };

      var options_centro_de_custo = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/centro_de_custo',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        }, 
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
      };
      
      var options_centro_de_custo_justificativas = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/centro_de_custo/justificativas',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        }, 
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
      };

      var options_conta_contabil = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/conta_contabil',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        }, 
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
      };
      
      var options_conta_contabil_justificativas = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/conta_contabil/justificativas',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        }, 
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
      };

      let Geral = [], Data_area = [], Data_agrupamento = [], Data_centro_de_custo = [], Data_conta_contabil = [], justificativas = [], Usuario = []
      const { usuarios } = this.state;

      request(options_geral, function (error, response, body) {
        this.setState({ mensagenStatus: "Carregando dados gerais" });
        
        if(body.mensagem === undefined) {
          
          Geral = body;

          request(options_area, function (error, response, body) {
            this.setState({ mensagenStatus: "Carregando dados das Áreas" });
            Data_area = body;
            
            request(options_area_justificativas, function (error, response, body) {
              this.setState({ mensagenStatus: "Carregando dados de justificativas das Áreas" });
              Data_area.forEach(function(d){
                justificativas = [];
                body.forEach(function(j){
                  if (j.DESC_AREA === d.DESC_AREA) {
                    if(j.JUSTIFICATIVA) {
                      Usuario = usuarios.filter(function(u){
                        return u.key === j.KEY_USUARIO
                      });
                      
                      if (Usuario.length > 0) {
                        j.JUSTIFICATIVA = j.JUSTIFICATIVA +" . Justificado por: "+ Usuario[0].nome_usuario;
                      }
                      j.JUSTIFICATIVA = j.JUSTIFICATIVA.toUpperCase();
                      justificativas.push(j)
                    }
                    
                  }
                }.bind(this));
                d.JUSTIFICATIVA = justificativas.length > 0 ? justificativas : null
              });
              
              request(options_agrupamento, function (error, response, body) {
                this.setState({ mensagenStatus: "Carregando Agrupamentos" });
                Data_agrupamento = body;
                
                request(options_agrupamento_justificativas, function (error, response, body) {
                  this.setState({ mensagenStatus: "Carregando Justificativas dos Agrupamentos" });
                  Data_agrupamento.forEach(function(d){
                    justificativas = [];
                    body.forEach(function(j){
                      if (j.AGRUPAMENTO === d.AGRUPAMENTO) {
                        if(j.JUSTIFICATIVA) {
                          Usuario = usuarios.filter(function(u){
                            return u.key === j.KEY_USUARIO
                          });

                          if (Usuario.length > 0) {
                            j.JUSTIFICATIVA = j.JUSTIFICATIVA +" . Justificado por: "+ Usuario[0].nome_usuario;
                          }
                          j.JUSTIFICATIVA = j.JUSTIFICATIVA.toUpperCase();
                          justificativas.push(j)
                        }

                      }
                    });
                    d.JUSTIFICATIVA = justificativas.length > 0 ? justificativas : null
                  });
                  
                  request(options_centro_de_custo, function (error, response, body) {
                    this.setState({ mensagenStatus: "Carregando Centro de Custo" });
                    Data_centro_de_custo = body;
                    
                    request(options_centro_de_custo_justificativas, function (error, response, body) {
                      this.setState({ mensagenStatus: "Carregando Justificativas dos Centros de Custo" });
                      Data_centro_de_custo.forEach(function(d){
                        justificativas = [];
                        body.forEach(function(j){
                          if (j.DESC_CENTRO_DE_CUSTO === d.DESC_CENTRO_DE_CUSTO) {
                            if(j.JUSTIFICATIVA) {
                              Usuario = usuarios.filter(function(u){
                                return u.key === j.KEY_USUARIO
                              });

                              if (Usuario.length > 0) {
                                j.JUSTIFICATIVA = j.JUSTIFICATIVA +" . Justificado por: "+ Usuario[0].nome_usuario;
                              }
                              j.JUSTIFICATIVA = j.JUSTIFICATIVA.toUpperCase();
                              justificativas.push(j)
                            }

                          }
                        });
                        d.JUSTIFICATIVA = justificativas.length > 0 ? justificativas : null
                      });
                      request(options_conta_contabil, function (error, response, body) {
                        this.setState({ mensagenStatus: "Carregando Contas Contabeis" });
                        Data_conta_contabil = body;
                        request(options_conta_contabil_justificativas, function (error, response, body) {
                          this.setState({ mensagenStatus: "Carregando Justificativas das Contas Contabeis" });
                          Data_conta_contabil.forEach(function(d){
                            justificativas = [];
                            body.forEach(function(j){
                              if (j.DESC_CONTA_CONTABIL === d.DESC_CONTA_CONTABIL) {
                                if(j.JUSTIFICATIVA) {
                                  Usuario = usuarios.filter(function(u){
                                    return u.key === j.KEY_USUARIO
                                  });

                                  if (Usuario.length > 0) {
                                    j.JUSTIFICATIVA = j.JUSTIFICATIVA +" . Justificado por: "+ Usuario[0].nome_usuario;
                                  }
                                  j.JUSTIFICATIVA = j.JUSTIFICATIVA.toUpperCase();
                                  justificativas.push(j)
                                }

                              }
                            });
                            d.JUSTIFICATIVA = justificativas.length > 0 ? justificativas : null
                          });
                          this.setState({ geral: Geral, data_area: Data_area, data_agrupamento: Data_agrupamento, data_centro_de_custo: Data_centro_de_custo,
                                    data_conta_contabil: Data_conta_contabil, loaderPesquisar: false, openFiltros: false, mensagenStatus: ''});
                        }.bind(this));
                      }.bind(this));
                    }.bind(this));
                  }.bind(this));
                }.bind(this));
              }.bind(this));
            }.bind(this));
          }.bind(this)); 
        } else {
          NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
          setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
        }
      }.bind(this));
    } else {
      NotificationManager.warning("Preencha todos os campos");
      this.setState({ loaderPesquisar: false, mensagenStatus: '' });
    }     
    
}
    
  
  onRowSelected(event) {
    console.log(event.node.data);
  }
  
  usuarioJustificativa(value){
    const { usuarios } = this.state;
    let result = '';
    console.log(value)
    /*
    let Usuario = usuarios.filter(function(u){
      return u.key === value._cell.row.data.KEY_USUARIO 
    });
    if (Usuario.length > 0) {
      result = `Justificativa escrita por ${Usuario[0].nome_usuario}`;
      return result
    } else {
      return result
    }*/
    
    
  }
  
  formataMes(value){
    switch (value) {
      case '01':
        return 'JAN'
        break;
      case '02':
        return 'FEV'
        break;
      case '03':
        return 'MAR'
        break;
      case '04':
        return 'ABR'
        break;
      case '05':
        return 'MAI'
        break;
      case '06':
        return 'JUN'
        break;
      case '07':
        return 'JUL'
        break;
      case '08':
        return 'AGO'
        break;
      case '09':
        return 'SET'
        break;
      case '10':
        return 'OUT'
        break;
      case '11':
        return 'NOV'
        break;
      case '12':
        return 'DEZ'
        break;
    }
  }
  

  handleChangeAnos = (e, { value }) => { this.setState({ anos_selecionados: value })}
  handleChangeDescFilial = (e, { value }) => {this.setState({ desc_filial_selecionados: value })}
  handleChangeMesAbv = (e, { value }) => {this.setState({ mes_abv_selecionados: value })}
  handleChangeDescArea = (e, { value }) => {this.setState({ desc_area_selecionados: value })}
  handleChangeAgrupamento = (e, { value }) => {this.setState({ agrupamento_selecionados: value })}
  handleChangeDescCentroDeCusto = (e, { value }) => {this.setState({ desc_centro_de_custo_selecionados: value })}
  handleChangeDescContaContabil = (e, { value }) => {this.setState({ desc_conta_contabil_selecionados: value })}
  
  handleChangeAnoJustificativa = (e, { value }) => {this.setState({ anos_selecionadoJustificativa: value })}
  handleChangeDescFilialJustificativa = (e, { value }) => {this.setState({ desc_filial_selecionadoJustificativa: value })}
  handleChangeMesAbvJustificativa = (e, { value }) => {this.setState({ mes_abv_selecionadoJustificativa: value })}
  handleChangeDescAreaJustificativa = (e, { value }) => {this.setState({ desc_area_selecionadoJustificativa: value })}
  handleChangDescricaoJustificativa = (e, { value }) => {this.setState({ descricaoJustificativa: value.toUpperCase() })}
 

  
  pesquisarGraficoArea(e, row){
    
    this.setState({mensal:[], open: true, loaderGrafico: true});  
    
    
     var options_mensal = { method: 'POST',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/mensal',
      json: {
        "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
        "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
        "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
        "DESC_AREA":row._row.data.DESC_AREA,
        "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
        "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
        "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
        "USUARIO":this.props.usuario.key
      },                      
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
     request(options_mensal, function (error, response, body) {
       if(body.mensagem === undefined) {
         if(body.length > 0) {
           body.forEach(function(x){
             x.VALOR_ORCADO = x.VALOR_ORCADO !== null ? parseInt(x.VALOR_ORCADO) : 0;
             x.VALOR_REALIZADO = x.VALOR_REALIZADO !== null ? parseInt(x.VALOR_REALIZADO) : 0;
             x.VARIACAO_PERCENTUAL = x.VARIACAO_PERCENTUAL !== null ? parseInt(x.VARIACAO_PERCENTUAL) : 0;
             x.VARIACAO_VALOR_MONETARIO = x.VARIACAO_VALOR_MONETARIO !== null ? parseInt(x.VARIACAO_VALOR_MONETARIO) : 0;
           });
         }
        this.setState({mensal:body},()=> this.setState({open: true, loaderGrafico: false},()=>this.setState({modalHeader:" ÁREA "+ row._row.data.DESC_AREA, loaderGrafico: false})));  
       } else {
         this.setState({ loaderGrafico: false });
         NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
         setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
       }
    }.bind(this));
    
  }

  pesquisarGraficoAgrupamento(e, row){
    
    this.setState({open: true, mensal:[], modalHeader:" AGRUPAMENTO " + row._row.data.AGRUPAMENTO, loaderGrafico: true}); 
    
     var options_mensal = { method: 'POST',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/mensal',
      json: {
        "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
        "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
        "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
        "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
        "AGRUPAMENTO":row._row.data.AGRUPAMENTO,
        "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
        "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
        "USUARIO":this.props.usuario.key
      },                      
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
     request(options_mensal, function (error, response, body) {
       if(body.mensagem === undefined) {
          if(body.length > 0) {
           body.forEach(function(x){
             x.VALOR_ORCADO = x.VALOR_ORCADO !== null ? parseInt(x.VALOR_ORCADO) : 0;
             x.VALOR_REALIZADO = x.VALOR_REALIZADO !== null ? parseInt(x.VALOR_REALIZADO) : 0;
             x.VARIACAO_PERCENTUAL = x.VARIACAO_PERCENTUAL !== null ? parseInt(x.VARIACAO_PERCENTUAL) : 0;
             x.VARIACAO_VALOR_MONETARIO = x.VARIACAO_VALOR_MONETARIO !== null ? parseInt(x.VARIACAO_VALOR_MONETARIO) : 0;
           });
         }
        this.setState({mensal:body, loaderGrafico: false});
       } else {
         this.setState({ loaderGrafico: false})
         NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
         setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
       }
    }.bind(this));
    
  }

  pesquisarGraficoCentroDeCusto(e, row){
    
    this.setState({open: true, loaderGrafico: true, mensal:[], modalHeader:" CENTRO DE CUSTO "+row._row.data.DESC_CENTRO_DE_CUSTO});  
    
     var options_mensal = { method: 'POST',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/mensal',
      json: {
        "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
        "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
        "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
        "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
        "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
        "DESC_CENTRO_DE_CUSTO":row._row.data.DESC_CENTRO_DE_CUSTO,
        "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
        "USUARIO":this.props.usuario.key
      },                      
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
     request(options_mensal, function (error, response, body) {
       if(body.mensagem === undefined) {
          if(body.length > 0) {
           body.forEach(function(x){
             x.VALOR_ORCADO = x.VALOR_ORCADO !== null ? parseInt(x.VALOR_ORCADO) : 0;
             x.VALOR_REALIZADO = x.VALOR_REALIZADO !== null ? parseInt(x.VALOR_REALIZADO) : 0;
             x.VARIACAO_PERCENTUAL = x.VARIACAO_PERCENTUAL !== null ? parseInt(x.VARIACAO_PERCENTUAL) : 0;
             x.VARIACAO_VALOR_MONETARIO = x.VARIACAO_VALOR_MONETARIO !== null ? parseInt(x.VARIACAO_VALOR_MONETARIO) : 0;
           });
         }
        this.setState({mensal:body, loaderGrafico: false});  
       } else {
         this.setState({loaderGrafico: false})
         NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
         setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
       }
    }.bind(this));
    
  }

  pesquisarGraficoContaContabil(e, row){
    
    this.setState({open: true, loaderGrafico: true, mensal:[], modalHeader:" CONTA CONTÁBIL "+row._row.data.DESC_CONTA_CONTABIL});
    
     var options_mensal = { method: 'POST',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/mensal',
      json: {
        "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
        "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
        "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
        "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
        "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
        "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
        "DESC_CONTA_CONTABIL":row._row.data.DESC_CONTA_CONTABIL,
        "USUARIO":this.props.usuario.key
      },                      
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
     request(options_mensal, function (error, response, body) {
       if(body.mensagem === undefined) {
         if(body.length > 0) {
           body.forEach(function(x){
             x.VALOR_ORCADO = x.VALOR_ORCADO !== null ? parseInt(x.VALOR_ORCADO) : 0;
             x.VALOR_REALIZADO = x.VALOR_REALIZADO !== null ? parseInt(x.VALOR_REALIZADO) : 0;
             x.VARIACAO_PERCENTUAL = x.VARIACAO_PERCENTUAL !== null ? parseInt(x.VARIACAO_PERCENTUAL) : 0;
             x.VARIACAO_VALOR_MONETARIO = x.VARIACAO_VALOR_MONETARIO !== null ? parseInt(x.VARIACAO_VALOR_MONETARIO) : 0;
           });
         }
        this.setState({mensal:body, loaderGrafico: false});
       } else {
         this.setState({loaderGrafico: false})
         NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
         setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
       }          
    }.bind(this));
    
  }
    close = () => this.setState({ open: false });

    closeFiltros = () => this.setState({ openFiltros: false });
  
    openModalFiltros = () => this.setState({ openFiltros: true });

    openModalJustificativas() {
      this.setState({ openModalJustificativa: true });
    }

    openModalTabelaJustificativas(e, data) {
      let DadosTabela = data._cell.row.data.JUSTIFICATIVA;
      if(DadosTabela){
        this.setState({
          openModalTabelaJustificativas: true, dataJustificativas: DadosTabela
        });
      }
    }

    closeModalTabelaJustificativas = () => this.setState({ openModalTabelaJustificativas: false });

    pesquisaDadosJustificativa() {
      this.setState({ loaderPesquisar: true});
      let mesSelecionado = '', dataFormatada = '';
      if(this.state.desc_filial_selecionadoJustificativa.length >= 2 && 
         this.state.anos_selecionadoJustificativa.length >= 2 &&
         this.state.mes_abv_selecionadoJustificativa.length >= 2 &&
         this.state.desc_area_selecionadoJustificativa.length >= 2) {
        switch ( this.state.mes_abv_selecionadoJustificativa) {
          case 'JAN':
            mesSelecionado = '01'
          break;
          case 'FEV':
            mesSelecionado = '02'
          break;
          case 'MAR':
            mesSelecionado = '03'
          break;
          case 'ABR':
            mesSelecionado = '04'
          break;
          case 'MAI':
            mesSelecionado = '05'
          break;
          case 'JUN':
            mesSelecionado = '06'
          break;
          case 'JUL':
            mesSelecionado = '07'
          break;
          case 'AGO':
            mesSelecionado = '08'
          break;
          case 'SET':
            mesSelecionado = '09'
          break;
          case 'OUT':
            mesSelecionado = '10'
          break;
          case 'NOV':
            mesSelecionado = '11'
          break;
          case 'DEZ':
            mesSelecionado = '12'
          break;
        }
        dataFormatada = this.state.anos_selecionadoJustificativa.concat(mesSelecionado);
        
        var options_dados_orcamento = { method: 'POST', 
                                        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/dados/justificativa',
                                        headers: {
                                          'content-type': 'application/json',
                                          'x-access-token': localStorage.getItem("token")
                                        },
                                       body: {"ID_TEMPO": dataFormatada, "DESC_FILIAL": this.state.desc_filial_selecionadoJustificativa, 
                                              "DESC_AREA": this.state.desc_area_selecionadoJustificativa}, 
                                       json: true
                                      };
        request(options_dados_orcamento, function (error, response, body) {
          this.setState({ data_justificativa: body, loaderPesquisar: false})
        }.bind(this));
      } else {
        NotificationManager.warning("Preencha todos os campos para realizar a pesquisa");
        this.setState({ loaderPesquisar: false});
      }
    }
    
    closeModalJustificativa = () => this.setState({ openModalJustificativa: false });

    openModalEditaJustificativa(data, acao){
      let editarJustificativa = false;
      if (acao === 'editar') {
        editarJustificativa = true
      } else {
        editarJustificativa = false
      }
      this.setState({descricaoJustificativa: data._row.data.JUSTIFICATIVA, openModalEditaJustificativa: true, idJustificativa: data._row.data.id, editarJustificativa: editarJustificativa});
    }

    openModalConsultaJustificativa(e, data){
      if (data._cell.row.data.JUSTIFICATIVA) {
        if (data._cell.row.data.JUSTIFICATIVA.length >= 1) {
          this.setState({descricaoJustificativa: data._cell.row.data.JUSTIFICATIVA, openModalEditaJustificativa: true, idJustificativa: data._cell.row.data.id, editarJustificativa: false});
        }
      }
      
    }
    
    closeModalEditaJustificativa = () => this.setState({ openModalEditaJustificativa: false });

    salvaJustificativa(){
      this.setState({ loaderPesquisar: true});
      const { idJustificativa, descricaoJustificativa } = this.state;
      
      
      
      var options_salva_justificativa = { method: 'PUT', 
                                        url: 'https://inteligenciaindustrial.com/budgetind/orcamento',
                                        headers: {
                                          'content-type': 'application/json',
                                          'x-access-token': localStorage.getItem("token")
                                        },
                                       body: { id:idJustificativa, JUSTIFICATIVA: descricaoJustificativa, KEY_USUARIO: localStorage.getItem("keyUsuario") }, 
                                       json: true
                                      };
        request(options_salva_justificativa, function (error, response, body) {
          this.setState({ loaderPesquisar: false});
          this.closeModalEditaJustificativa();
          this.pesquisaDadosJustificativa();
        }.bind(this));
    }

  salvarEdicaoJustificativa(data){
    this.setState({ loaderJustificativa: true});
    let id = data._cell.row.data.id, justificativa = data._cell.row.data.JUSTIFICATIVA;
    var options_salva_justificativa = { method: 'PUT', 
                                        url: 'https://inteligenciaindustrial.com/budgetind/orcamento',
                                        headers: {
                                          'content-type': 'application/json',
                                          'x-access-token': localStorage.getItem("token")
                                        },
                                       body: { id:id, JUSTIFICATIVA: justificativa, KEY_USUARIO: localStorage.getItem("keyUsuario") },
                                       json: true
                                      };
    request(options_salva_justificativa, function (error, response, body) {
      this.setState({ loaderJustificativa: false});
      //this.pesquisaDadosJustificativa();
    }.bind(this));
  }

  render() {
    const { anos_selecionados , 
           mes_abv_selecionados,
            desc_filial_selecionados,
            desc_area_selecionados,
            agrupamento_selecionados,
            desc_centro_de_custo_selecionados,
            desc_conta_contabil_selecionados,
            open, openFiltros, openModalJustificativa, closeOnEscape, closeOnDimmerClick} = this.state
    
  const config = {
      "numberFormatter": {
        "numberFormat": "#,###.##"
      },
      "type": "serial",
      "theme": "light",
      "marginRight": 40,
      "marginLeft": 40,
      "autoMarginOffset": 20,
      "mouseWheelZoomEnabled": false,
      "valueAxes": [{
        "id": "ax1",
        "axisAlpha": 0,
        "position": "left",
        "ignoreAxisWidth": true,
        "labelsEnabled": false,
        "gridThickness":0
      }],
      "balloon": {
        "borderThickness": 1,
        "shadowAlpha": 0
      },
      "graphs": [{
        "id": "VALOR_REALIZADO",
        "type": "column",
        "balloon":{
          "drop": true,
          "adjustBorderColor": false,
          "color":"#ffffff"
        },
       
        "fillAlphas": 0.9,
			  "fillColors": "#1a69a4",   
        "lineColor": "#1a69a4",   
        "title": "REALIZADO",
        "valueField": "VALOR_REALIZADO",
        "showBalloon": false,
        "labelText" : "[[value]]",
        "labelRotation": -90
      },{
        "id": "VALOR_ORCADO",
        "type": "column",
        "balloon":{
          "drop": true,
          "adjustBorderColor": false,
          "color":"#ffffff"
        },
       
        "fillAlphas": 0.9,
			  "fillColors": "#1a69a4ad",                
        "lineColor": "#1a69a4ad",  
        "title": "ORÇADO",
        "valueField": "VALOR_ORCADO",
        "showBalloon": false,
        "labelText" : "[[value]]",
        "labelRotation": -90
      },
                {
        "id": "VARIACAO_VALOR_MONETARIO",
        "type": "column",
        "balloon":{
          "drop": true,
          "adjustBorderColor": false,
          "color":"#ffffff"
        },
       
        "fillAlphas": 0.9,
			  "fillColors": "#767676c4",                
        "lineColor": "#767676c4",  
        "title": "VARIAÇÃO $",
        "valueField": "VARIACAO_VALOR_MONETARIO",
        "showBalloon": false,
        "labelText" : "[[value]]",
        "hidden":true,
        "labelPosition":"top",
        "labelFunction": function(graphDataItem, graph){
          let a = 0, b = 0;
          if(graphDataItem.values.value !== null){
            a = graphDataItem.values.value
          };
          if (graphDataItem.dataContext.VARIACAO_PERCENTUAL !== null) {
            b = graphDataItem.dataContext.VARIACAO_PERCENTUAL
          }
            var value = formatDecimal(parseInt(a), opts2) + " ("+formatDecimal(parseInt(b), opts2)+"%)";
           // value = formatTextLabel(value);
            return value;
        },
        "labelRotation": -90
      }],
      "categoryField": "MES_ABV",
      "categoryAxis": {
        "parseDates": false,
        "dashLength": 1,
        "minorGridEnabled": true,
        "gridThickness": 0
      },
    	"legend": {
        "enabled": true,
        "useGraphSettings": true
      },
      "dataProvider": this.state.mensal
    };
    
 
    
    let styleCondition = this.state.geral[0].VARIACAO_PERCENTUAL > 0 ? {textAlign:'center', color:'red'} : {textAlign:'center', color:'green'};
    
    let Options = {responsiveLayout:'collapse', responsiveLayoutCollapseStartOpen:false }
    
    var justificativaIcon = function(cell, formatterParams) { //plain text value
      if (cell._cell.row.data.JUSTIFICATIVA) {
        return '<i aria-hidden="true" class="info icon"></i>';
      }
    };

    
    const data_justificativa_colunas = [
          {visible:false,
        formatter:function responsiveCollapse(cell, formatterParams, onRendered) {
          var self = this, open = false, el = document.createElement("div");
          
          if (document.getElementsByClassName('tabulator-responsive-collapse-toggle')[document.getElementsByClassName('tabulator-responsive-collapse-toggle').length-1] !== undefined) {
            let elemento = document.getElementsByClassName('tabulator-responsive-collapse-toggle')[document.getElementsByClassName('tabulator-responsive-collapse-toggle').length-1];
            let cellData = new Date(cell._cell.row.data.createdAt);
            
          }
          
          function toggleList(isOpen) {
            var collapse = cell.getRow().getElement().getElementsByClassName("tabulator-responsive-collapse")[0];
            open = isOpen;
            if (open) {
              el.classList.add("open");
              if (collapse) {
                collapse.style.display = '';
              }
            } else {
              el.classList.remove("open");
              if (collapse) {
                collapse.style.display = 'none';
              }
            }
          }
          
          el.classList.add("tabulator-responsive-collapse-toggle");
          el.innerHTML = "<span class='tabulator-responsive-collapse-toggle-open' >+</span><span class='tabulator-responsive-collapse-toggle-close'>-</span>";
          cell.getElement().classList.add("tabulator-row-handle");
          if (self.table.options.responsiveLayoutCollapseStartOpen) {
            open = true;
          }
          el.addEventListener("click", function (e) {
            e.stopImmediatePropagation();
            toggleList(!open);
          });
          toggleList(open);
          return el;
        }, width:30, minWidth:30, align:"center", headerSort:false
      },

          { title: "DATA", field: "ID_TEMPO", align: "center", width : 70, responsive:0, visible:false, resizable:false, headerSort:false, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value[4] + value[5] + "/" + value[0] + value[1] + value[2] + value[3]
            }
          },
          { title: "MÊS", field: "ID_TEMPO", align: "center", width : 70, responsive:1, headerSort:true,  headerFilter:"input", headerFilterPlaceholder:" ", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              var numMes = value[4].concat(value[5]);              
              return this.formataMes(numMes)
            }.bind(this)
          },
          { title: "FILIAL", field: "DESC_FILIAL", align: "center", width : 50, responsive:1, headerSort:false, visible:false },
          { title: "ÁREA", field: "DESC_AREA", align: "center", width : 130, responsive:2, headerSort:false, visible:false },
          { title: "AGRUPAMENTO", field: "AGRUPAMENTO", align: "center", width : 190, headerSort:true, headerFilter:"input" },
          { title: "CENTRO DE CUSTO", field: "DESC_CENTRO_DE_CUSTO", align: "center", width : 160, headerSort:true, headerFilter:"input" },
          { title: "CONTA CONTÁBIL", field: "DESC_CONTA_CONTABIL", align: "center", width : 260, headerSort:true, headerFilter:"input" },
          { title: "ORÇADO", field: "VALOR_ORCADO", align: "center", width : 90, headerSort:true, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            }
          },
          { title: "REAL", field: "VALOR_REALIZADO", align: "center", width : 70, headerSort:true, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            }
          },
          { title: "VAR. $", field: "VARIACAO_VALOR_MONETARIO", align: "center", width : 70, headerSort:true, formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                }else{
                  return 0
                }
            }
          },
          { title: "VAR. %", field: "VARIACAO_PERCENTUAL", align: "center", width : 75, headerSort:true, formatter:function(cell, formatterParams){
              var value = cell.getValue();
              if (value > 0) {
                return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
              } else if(value < 0) {
                return "<span style='color:green; font-weight:bold;'>" + value + "</span>";
              }else{
                return 0
              }
            }
          },
          { title: "JUSTIFICATIVA", field: "JUSTIFICATIVA", align: "center", headerSort: false, minWidth:200, editor:true, formatter:"textarea"
          },
          { title: "", align: "center", cellClick: this.openModalConsultaJustificativa.bind(this), headerSort: false, responsive: 0, formatter: justificativaIcon, width: 40, visible:false }
        ];

    return (
          <div>
            <div style = {{ paddingTop: "5px", paddingBottom: "20px", paddingRight: "15px" }}>
              <Button floated='right' icon labelPosition='right' color='blue'  onClick={this.openModalFiltros.bind(this)}>
                Filtrar
                <Icon name='filter' />
              </Button>
              <Button floated='right' icon labelPosition='right' color='gray'  onClick={this.openModalJustificativas.bind(this)}>
                Justificativas
                <Icon name='wordpress forms' />
              </Button>
            </div>
            {/*MODAL JUSTIFICATIVAS*/}
            <Modal open={openModalJustificativa} onClose={this.closeModalJustificativa} closeIcon style = {{width: "96%", height: "auto"}}>
              <Header icon='wordpress forms' content='JUSTIFICATIVAS' />
              <Modal.Content>
                <NotificationContainer/>
                <Form>
                  <Form.Group widths='equal'>
                    <Form.Field>
                    <label>Filial:</label>
                    <Dropdown placeholder='FILIAL' fluid  search selection options={this.state.filial}  value={this.state.desc_filial_selecionadoJustificativa} onChange={this.handleChangeDescFilialJustificativa}/>
                  </Form.Field>
                  <Form.Field >
                    <label>Ano:</label>
                    <Dropdown placeholder='ANO' fluid  search selection options={this.state.ano} value={this.state.anos_selecionadoJustificativa} onChange={this.handleChangeAnoJustificativa}/> 
                  </Form.Field>
                  <Form.Field>
                    <label>Mês:</label>
                    <Dropdown placeholder='MÊS' fluid  search selection options={this.state.mes} value={this.state.mes_abv_selecionadoJustificativa} onChange={this.handleChangeMesAbvJustificativa}/>
                  </Form.Field>
                </Form.Group>
                 <Form.Group widths='equal'>
                    <Form.Field>
                       <label>Área:</label>
                       <Dropdown placeholder='AREA' fluid  search selection options={this.state.areaJustificativas} value={this.state.desc_area_selecionadoJustificativa} onChange={this.handleChangeDescAreaJustificativa}/>
                    </Form.Field>
                    <Form.Field>
                      <label style={{color:'white'}}>Pesquisar</label>
                       <Button floated='right' loading = { this.state.loaderPesquisar } disabled = { this.state.loaderPesquisar } icon labelPosition='right' color='blue' onClick = {this.pesquisaDadosJustificativa.bind(this)}>
                       Pesquisar
                      <Icon name='search' />
                      </Button>
                    </Form.Field>
                </Form.Group>
             </Form>
                <Segment style={{width: 'auto', height: '360px', margin: 'auto', padding: '0px'}}>
                  { this.state.loaderJustificativa === true && <Dimmer active inverted>
                      <Loader inverted>Salvando Justificativa</Loader>
                    </Dimmer>
                  }
                  { 
                    this.state.loaderDados === true && <Dimmer active inverted>
                      <Loader inverted content='Carregando dados' />
                    </Dimmer>
                  }
                  <ReactTabulator data={this.state.data_justificativa} style={{ height: '-webkit-fill-available',margin:'0px', fontSize:'10px','border':'0px'}}
                                  options={Options} className={'very compact celled'}
                                  columns={data_justificativa_colunas}
                                 
                                  rowDblClick = {function(e, data){
                                    this.openModalEditaJustificativa(data, 'editar')
                                  }.bind(this)}
                                  cellEdited = {function(e, data){
                                    this.salvarEdicaoJustificativa(e)
                                  }.bind(this)}
                                  tooltips={true}
                                  layout="fitColumns"/>
                </Segment>
              </Modal.Content>
            </Modal>
    
            <Modal open={this.state.openModalEditaJustificativa} onClose={this.closeModalEditaJustificativa} closeIcon style= {{paddingBottom: "10px"}}>
              <Header icon='write' content='JUSTIFICATIVA' />
              <Modal.Content>
                <Form>
                  <Form.Field>
                    <TextArea placeholder='Digite aqui a justificativa' value={this.state.descricaoJustificativa} onChange={this.handleChangDescricaoJustificativa} rows = "14"/>
                 
                  </Form.Field>
                  {this.state.editarJustificativa === true && 
                  <Button floated='right' loading = { this.state.loaderPesquisar } disabled = { this.state.loaderPesquisar} icon labelPosition='right' color='blue' onClick={this.salvaJustificativa.bind(this)}>
                    Salvar
                    <Icon name='save outline' />
                  </Button>
                  }
                </Form>
              </Modal.Content>
            </Modal>
      
            <Modal open={this.state.openModalTabelaJustificativas} onClose={this.closeModalTabelaJustificativas} closeIcon style = {{width: "96%", height: "auto"}}>
              <Header icon='wordpress forms' content='JUSTIFICATIVAS' />
              <Modal.Content style={{padding: '0px'}}>
                <Segment style={{width: 'auto', height: '360px', margin: 'auto', padding: '0px'}}>
                  <ReactTabulator data={this.state.dataJustificativas} style={{ height: '-webkit-fill-available',margin:'0px', fontSize:'10px','border':'0px'}}
                                  options={Options} className={'very compact celled'}
                                  columns={data_justificativa_colunas}
                                  tooltips={true}
                                  layout="fitColumns"/>
                </Segment>
              </Modal.Content>
            </Modal>
            {/*--------openModalTabelaJustificativas------------*/}


          
            <Modal open={openFiltros} onClose={this.closeFiltros} closeIcon>
              <Header icon='filter' content='FILTROS' />
              <Modal.Content>
                <NotificationContainer/>
                <Form>
              <Form.Group widths='equal'>
                  <Form.Field style={{width:'150px'}}>
                    <label>Filial:</label>
                    <Dropdown placeholder='FILIAL' fluid multiple search selection options={this.state.filial}  value={desc_filial_selecionados} onChange={this.handleChangeDescFilial}/>
                  </Form.Field>
                  <Form.Field style={{width:'150px'}}>
                    <label>Ano:</label>
                    <Dropdown placeholder='ANO' fluid multiple search selection options={this.state.ano} value={anos_selecionados} onChange={this.handleChangeAnos}/> 
                  </Form.Field>
                  <Form.Field style={{width:'150px'}}>
                    <label>Mês:</label>
                    <Dropdown placeholder='MÊS' fluid multiple search selection options={this.state.mes} value={mes_abv_selecionados} onChange={this.handleChangeMesAbv}/>
                  </Form.Field>
                </Form.Group>
                 <Form.Group widths='equal'>
                    <Form.Field>
                       <label>Área:</label>
                       <Dropdown placeholder='AREA' fluid multiple search selection options={this.state.area} value={desc_area_selecionados} onChange={this.handleChangeDescArea}/>
                    </Form.Field>
                    <Form.Field >
                      <label>Agrupamento:</label>
                      <Dropdown placeholder='AGRUPAMENTO' fluid multiple search selection options={this.state.agrupamento} value={agrupamento_selecionados} onChange={this.handleChangeAgrupamento}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field >
                      <label>Centro de Custo:</label>
                      <Dropdown placeholder='CENTRO DE CUSTO' fluid multiple search selection options={this.state.centro_de_custo} value={desc_centro_de_custo_selecionados} onChange={this.handleChangeDescCentroDeCusto}/>
                    </Form.Field>
                      <Form.Field>
                      <label>Conta Contábil:</label>
                      <Dropdown placeholder='CONTA CONTÁBIL' fluid multiple search selection options={this.state.conta_contabil} value={desc_conta_contabil_selecionados} onChange={this.handleChangeDescContaContabil}/>    
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                      <label style={{color:'white'}}>Pesquisar</label>
                       <Button floated='right' loading = { this.state.loaderPesquisar } disabled = { this.state.loaderPesquisar } icon labelPosition='right' onClick={this.pesquisar.bind(this)} color='blue'>
                       Pesquisar
                      <Icon name='search' />
                      </Button>
                    </Form.Field>
                </Form.Group>
             </Form>
              {
                this.state.mensagenStatus !== '' &&
                <Label style={{width: '-webkit-fill-available', marginTop: '0px', paddingTop: '8px', paddingBottom: '5px', backgroundColor: '#595959', 
                              color: 'white'}} attached='bottom left'>
                  {this.state.mensagenStatus}
                </Label>
              }
              
              </Modal.Content>             
            </Modal>




            <Segment basic style={{marginBottom:'0px',paddingBottom:'0px'}}>
     

           </Segment >
           <Segment basic style={{marginTop:'0px'}}>
             <Grid columns={4} > 
                 <Grid.Row style={{paddingTop: '0px'}}>               
                  <Grid.Column >
                    <Card style={{width: "100%"}}>
                      <Card.Content header='Orçado' />
                      <Card.Content >
                       <Header as='h1' style={{textAlign:'center', color:'blue'}}>
                          R$ {this.state.geral[0].VALOR_ORCADO !== null ? formatDecimal(parseInt(this.state.geral[0].VALOR_ORCADO), opts) : '0,00'}
                       </Header>
                     </Card.Content>
                   </Card>
                  </Grid.Column>
                   <Grid.Column >
                     <Card style={{width: "100%"}}>
                      <Card.Content header='Realizado' />
                      <Card.Content >
                       <Header as='h1' style={{textAlign:'center', color:'blue'}}>
                          R$ {this.state.geral[0].VALOR_REALIZADO !== null ? formatDecimal(parseInt(this.state.geral[0].VALOR_REALIZADO), opts) : '0,00'}
                       </Header>
                     </Card.Content>
                   </Card>
                  </Grid.Column>
                   <Grid.Column >
                     <Card style={{width: "100%"}}>
                      <Card.Content header='Variação $' />
                      <Card.Content >
                       <Header as='h1' style={styleCondition}>
                          R$ {this.state.geral[0].VARIACAO_VALOR_MONETARIO !== null ? formatDecimal(parseInt(this.state.geral[0].VARIACAO_VALOR_MONETARIO), opts) : '0,00'}
                       </Header>
                     </Card.Content>
                   </Card>
                  </Grid.Column>
                   <Grid.Column >
                    <Card style={{width: "100%"}}>
                      <Card.Content header='Variação %' />
                      <Card.Content >
                       <Header as='h1' style={styleCondition}>
                          {this.state.geral[0].VARIACAO_PERCENTUAL !== null ? formatDecimal(parseFloat(this.state.geral[0].VARIACAO_PERCENTUAL), opts) : '0,00'} %
                       </Header>
                     </Card.Content>
                   </Card>
                  </Grid.Column>
              </Grid.Row> 
              
            </Grid>
          </Segment >

           <Segment basic style={{marginTop:'0px'}}>

            <Grid columns={2} >
              <Grid.Row style={{height: '300px', marginBottom:'80px'}} >               
                <Grid.Column style={{height: '300px', paddingBottom:'10px'}}>
                   <Header as='h5' attached='top'>
                    Orçamento por Área
                  </Header>
                    <Segment attached style={{height: '300px', padding: '0px'}}>
                  <ReactTabulator data={this.state.data_area} style={{ height: '-webkit-fill-available', margin:'0px', fontSize:'9px','border':'0px'}}
                                  options={this.state.options} className={'very compact celled'}
                                  columns={this.state.data_area_colunas}
                                  rowDblClick={this.pesquisarGraficoArea.bind(this)}
                                  tooltips={true}
                                  layout={"fitColumns"}/>     
              </Segment >
                </Grid.Column>
                <Grid.Column style={{height: '300px', paddingBottom:'10px'}}>
                    <Header as='h5' attached='top'>
                    Orçamento por Agrupamento
                  </Header>
                    <Segment attached style={{height: '300px', padding: '0px'}}>
                     <ReactTabulator data={this.state.data_agrupamento} style={{ height: '-webkit-fill-available', margin:'0px', fontSize:'9px','border':'0px'}}
                                  options={this.state.options} className={'very compact celled'}
                                  columns={this.state.data_agrupamento_colunas}
                                  rowDblClick={this.pesquisarGraficoAgrupamento.bind(this)}
                                  tooltips={true}
                                  layout={"fitColumns"}/> 
</Segment >

                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{height: '300px', marginBottom:'50px'}}>
               
                <Grid.Column style={{height: '300px', paddingBottom:'10px'}}>
                    <Header as='h5' attached='top'>
                    Orçamento por Centro de Custo
                  </Header>
                    <Segment attached style={{height: '300px', padding: '0px'}}>
                    <ReactTabulator data={this.state.data_centro_de_custo} style={{ height: '-webkit-fill-available',margin:'0px', fontSize:'9px','border':'0px'}}
                                  options={this.state.options} className={'very compact celled'}
                                  columns={this.state.data_centro_de_custo_colunas}
                                  rowDblClick={this.pesquisarGraficoCentroDeCusto.bind(this)}
                                  tooltips={true}
                                  layout={"fitColumns"}/>  
</Segment >
                </Grid.Column>
                <Grid.Column style={{height: '300px', paddingBottom:'10px'}}>
                     <Header as='h5' attached='top'>
                    Orçamento por Conta Contábil
                  </Header>
                    <Segment attached style={{height: '300px', padding: '0px'}}>
                      <ReactTabulator  data={this.state.data_conta_contabil} style={{ height: '-webkit-fill-available', margin:'0px', fontSize:'9px','border':'0px'}}
                                  options={this.state.options} className={'very compact celled'}
                                  columns={this.state.data_conta_contabil_colunas}
                                  rowDblClick={this.pesquisarGraficoContaContabil.bind(this)}
                                  tooltips={true}
                                  layout={"fitColumns"}/>
                    </Segment >
                </Grid.Column>
              </Grid.Row>
            </Grid>
            </Segment >
            <Modal
              open={open}              
              style={{'width':'90%'}}
              onClose={this.close}
              closeIcon>
              <Header icon='chart bar' content={'ANÁLISE MENSAL ' + this.state.modalHeader} />
              <Modal.Content>
                <NotificationContainer/>
                { this.state.loaderGrafico === true && <Dimmer active inverted>
                  <Loader inverted content='Carregando dados' />
                </Dimmer>}
                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
              </Modal.Content>             
            </Modal>
           </div>
    );
  }
}

function currencyFormatter(params) {
  return  formatNumber(params.value);
}
function formatNumber(number) {
  return number !== null ? formatDecimal(parseInt(number), opts) : 0
}

export default App;
