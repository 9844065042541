import React, { Component } from 'react';

import { Grid, Header, Icon, Modal, Card } from 'semantic-ui-react';
import PubSub from 'pubsub-js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

var request = require("request");

class Headcount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
    this.download = this.download.bind(this)
  }
  
  componentDidMount(){
    
  }
  
  voltar(){
    PubSub.publish('componente', { componente:'' });
  }
  
  close = () => this.setState({ open: false });

  download(value){
    console.log(this.props)
    const get_token = { 
      method: 'GET', 
      url: 'https://inteligenciaindustrial.com/budgetind/headcount/token',
      headers: {
        'content-type': 'application/json',
        'x-access-token': localStorage.getItem("token")
      },
      body: { keyUsuario: localStorage.getItem("keyUsuario") },
      json: true
    };
    
    request(get_token, function (error, response, body){
      if(body.mensagem === undefined) {
        window.open(`https://inteligenciaindustrial.com/budgetind/headcount/download/${body+"&"+value}`)
      } else {
        NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
        setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
      }
    }.bind(this));
  }
  
  render() {
    const { open } = this.state;
    
    return(
      <div>
        <Modal open={open} style={{ width: 'auto' }} onClose={this.voltar} closeIcon>
          <Header icon='users' content={'HEADCOUNT'} />
          <Modal.Content>
            <NotificationContainer/>
            <Card.Group centered>
              <Card onClick = { () => this.download('headcount_corporativo') } style = {{ width: "160px"}}>
                <Card.Content>
                  <Card.Header>CORPORATIVO</Card.Header>
                  <Card.Meta>2020</Card.Meta>
                  <Card.Description style = {{ textAlign: 'center'}}>
                    <Icon name='sitemap' size='big' />
                  </Card.Description>
                </Card.Content>
              </Card>

              <Card onClick = { () => this.download('headcount_frutal') } style = {{ width: "160px"}}>
                <Card.Content>
                  <Card.Header>FRUTAL</Card.Header>
                  <Card.Meta>2020</Card.Meta>
                  <Card.Description style = {{ textAlign: 'center'}}>
                    <Icon name='sitemap' size='big' />
                  </Card.Description>
                </Card.Content>
              </Card>

              <Card onClick = { () => this.download('headcount_petropolis') } style = {{ width: "160px"}}>
                <Card.Content>
                  <Card.Header>PETRÓPOLIS</Card.Header>
                  <Card.Meta>2020</Card.Meta>
                  <Card.Description style = {{ textAlign: 'center'}}>
                    <Icon name='sitemap' size='big' />
                  </Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
          </Modal.Content>             
        </Modal>
      </div>
    );
  }
}

export default Headcount;